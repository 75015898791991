import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import {
  CustomerPortalSingleCompany,
} from '@/helpers/types/customerPortalTypes';
import { fetchCompanyByUuid } from "@/api/customerPortal/companies/fetchCompanyByUuid";



type FetchCompanyByUuidResponse = {
  data: CustomerPortalSingleCompany;
};

type FetchCompanyByUuidArgs = {
  referenceKey: string;
  uuid: string;
};


type FetchCompanyError = string | SerializedError;

// Fetch Single Company
export const fetchSingleCompanyData = createAsyncThunk<
  FetchCompanyByUuidResponse,
  FetchCompanyByUuidArgs,
  {
    rejectValue: FetchCompanyError;
  }
>(
  'customer-portal/companies/viewCompany',
  async (FetchQuoteByUuidArgs, { rejectWithValue }) => {
    try {
      const response = await fetchCompanyByUuid(FetchQuoteByUuidArgs);
      const data: CustomerPortalSingleCompany = response?.data;
      return { data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
