import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ReportComponentState {
    currentComponent: string;
}

const initialState: ReportComponentState = {
    currentComponent: 'exports',
};

const reportComponentSlice = createSlice({
    name: 'report-component',
    initialState,
    reducers: {
        setComponent: (state, action: PayloadAction<string>) => {
            state.currentComponent = action.payload;
        },
    },
});

export const { setComponent } = reportComponentSlice.actions;

export default reportComponentSlice.reducer;
