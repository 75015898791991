import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { CompanyDetails } from '@/helpers/types';
import { GetCompanyDetails } from '@/api/settings/getCompanyDetails';

// Define the type for the API response
interface CompanyDetailsResponse {
  data: CompanyDetails;
}

export const fetchCompanyDetails = createAsyncThunk<
  CompanyDetailsResponse,
  void,
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>('company/fetchCompanyDetails', async (_, { rejectWithValue }) => {
  try {
    return await GetCompanyDetails();
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
