// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_METHOD_POST,
  API_GET_COMPANY_DETAILS,
} from '@/constants/apiConstants';

export const GetCompanyDetails = async () => {
  const method: Method = API_METHOD_POST;
  const url = API_GET_COMPANY_DETAILS;

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error;
  }
};
