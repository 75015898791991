import { GetEnquiryStatus } from '@/api/enquiry/getEnquiryStatus';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { lastUpdate } from "@/api/common/lastUpdate";

type LastUpdateData = {
  user_updated: boolean;
  company_settings_updated: boolean;
};
type FetchLastUpdateResponse = {
  data: LastUpdateData;
};

type FetchLastUpdateError = string | SerializedError;

// Fetch last update
export const fetchLastUpdate = createAsyncThunk<
  FetchLastUpdateResponse,
  void,
  { rejectValue: FetchLastUpdateError }
>('common/lastUpdate', async (_, { rejectWithValue }) => {
  try {
    const response = await lastUpdate();
    return response;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});
